// color variable
// main color
// ! $main_color: #00c8ff;
$primary_color: #50b9e2;
$sec_color: #193952;
$secondary_color: #d3dbf8;
$accent_color: #234bdc;

$primary_color_overlay: #50b9e26b;
$accent_color_overlay: #234bdc34;
$secondary_color_overlay: #d3dbf863;

$white_color: #fff;
// footer
$footer_color: #1c3a52;
$footer_line: #314d63;
// home page
// landing sections
$header_color: #1d3c51;
$landing_p_color: #4e646f;
$landing_cta_color: #ffdd61;
// how work
$how_work_header: #1d394e;
$how_work_paragraph: #8399a6;
$how_work_bg-color: #e7f6fb;
$step_header: #203a51;
$step_paragraph: #83969d;

$sec_header_paragraph: #8d9ba6;
$card_header: #213a4e;
$card_paragraph: #20343f;

$comparison_header: #1e3a4f;
$comparison_paragraph: #8d9c9f;

$first_header_color: #213d53;
$first_header_bg: #e7e7e7;
$sec_header_bg: #1c3a52;

$row_header: #18364e;
$row_bg: #f5f5f5;
$sec_column_overlay: #b0eefe79;

$true_icon-bg: #36bd5f;
$false_icon-bg: #d51a21;
$padding_row: 10px 20px;
$width_first: 324px;
$width_sec: 445px;

$width_first_medium: 200px;
$width_sec_medium: 295px;

// $width_first_small: 70px;
// $width_sec_small: 125px;
$width_first_small: 90px;
$width_sec_small: 145px;

$pricing_header: #1c3b52;
$pricing_paragraph: #8d9da8;

$pricing_page_bg: #e6f6fa;
$margin_page_top: 41px;
$subtitle: 18px;
