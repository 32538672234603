@use "./../../../common/variable" as v;

main.aboutUs {
  div.container {
    margin-top: v.$margin_page_top;
    padding: 0;
    div.aboutUs-top {
      text-align: center;
      padding: 0 15px;
      h2 {
        color: v.$pricing_header;
        font-size: 37px;
        font-weight: 800;
        margin-bottom: 7px;
      }

      p {
        color: v.$pricing_paragraph;
        font-size: 22px;
        font-weight: 700;
      }
    }

    div.aboutUs-bottom {
      background-color: #e6f6fa;
      // margin-top: 157px;
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 90px 36px;
      border-radius: 28px;
      @media (max-width: 767.98px) {
        padding: 71px 15px;
      }
      @media (max-width: 991.98px) {
        gap: 50px;
        flex-direction: column;
      }
      div.aboutUs-bottom-left {
        padding: 0 4px;
        @media (max-width: 767.98px) {
          width: 100%;
        }
        div.cart-top {
          display: flex;
          flex-direction: column;
          gap: 22px;
          margin-bottom: 58px;
          @media (max-width: 767.98px) {
            text-align: center;
            font-size: 36px;
          }
          @media (min-width: 767.98px) and (max-width: 991.98px) {
            text-align: center;
          }
          h3 {
            font-size: 30px;
            color: v.$pricing_header;
          }

          p {
            font-size: 20px;
            font-weight: 800;
            max-width: 650px;
            line-height: 1.7;
            word-spacing: -3px;
            color: v.$pricing_header;
            @media (max-width: 767.98px) {
              font-size: 22px;
            }
          }
        }

        div.cart-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-items: flex-start;
          gap: 50px;
          @media (max-width: 767.98px) {
            flex-direction: column;
          }
          div.item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            div.hand-img {
              width: 100px;
              align-self: flex-end;
              img {
                width: 100%;
                height: 100%;
              }
            }
            div.item-text {
              h3 {
                font-size: 23px;
                margin-bottom: 7px;
                word-spacing: -3px;
                color: v.$pricing_header;
                @media (max-width: 991.98px) {
                  font-size: 24px;
                }
              }

              p {
                max-width: 185px;
                font-size: 16px;
                line-height: 1.4;
                color: v.$pricing_header;
              }
            }
          }
          div.item1 {
            div.hand-img {
              width: 63px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          div.item2 {
            div.hand-img {
              width: 60px;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }

      div.aboutUs-bottom-right {
        div.hand-image {
          // width: 368px;
          width: 380px;
          height: 368px;
          position: relative;
          z-index: 1;
          @media (max-width: 767.98px) {
            width: 321px;
            height: 316px;
          }
          img {
            width: 100%;
            height: 100%;
            transform: translateX(-33px);
            @media (max-width: 767.98px) {
              transform: translateX(-11px) translateY(-10px);
            }
          }
          &::before {
            content: "";
            position: absolute;
            width: 382px;
            height: 382px;
            background-color: #00c7ff;
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -1;
            @media (max-width: 767.98px) {
              width: 330px;
              height: 330px;
            }
          }
        }
      }
    }
  }
}
