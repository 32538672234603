@use "./../../../../../../common/variable" as v;

div.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  max-width: 300px;
  min-height: 221px;
  opacity: 0;
  @media (max-width: 767.98px) {
    min-height: 138px;
    margin: auto;
  }
  @media (min-width: 767.98px) and (max-width: 991.98px) {
    min-height: 288px;
  }
  div.hand-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 90px;
    border-radius: 24px;
    background-color: v.$secondary_color_overlay;
    border-radius: 15px;
    @media (max-width: 767.98px) {
      margin: 0 auto;
    }
    div.images-in {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      i {
        font-size: 2rem;
        color: v.$primary_color;
      }
    }
  }

  h4 {
    color: v.$card_header;
    font-size: 19px;
    font-weight: 800;
    text-align: center;
  }

  p {
    color: v.$card_paragraph;
    font-size: 16px;
    max-width: 291px;
    text-align: center;

    a {
      color: v.$primary_color;
      font-weight: 300;
    }
  }
}
div.card.card7 {
  div.hand-img {
    div.image-in {
      position: relative;
      top: 9px;
    }
  }
}

div:is(.card.card1, .card.card2, .card.card3) {
  div.hand-img {
    div.image-in {
      width: 37px;
    }
  }
}
