@use "./../../../../../common/variable" as v;

section.homeLanding {
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  div.container-wrapped {
    position: relative;
    min-height: 500px;
    // padding-bottom: 161px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767.98px) {
      min-height: calc(100vh - 70px);
      overflow: hidden;
      display: flex;
      flex-direction: column;
      gap: 6.25rem;
    }
    @media (max-width: 575.98px) {
      gap: 7rem;
    }
    div.container {
      flex: 1;
      // margin-top: 150px;
      @media (max-width: 767.98px) {
        margin-top: 41px;
        margin-bottom: 100px;
      }

      &.landing-box {
        h1.landing-header {
          margin-bottom: 10px;
          color: #1d3c51;
          font-size: 30px;
          font-weight: 800;
          padding: 0 27px;
          line-height: 1;
          word-spacing: -1px;
          height: fit-content;
          @media (max-width: 767.98px) {
            height: fit-content;
            padding: 0;
          }
          @media (min-width: 767.98px) and (max-width: 991.98px) {
            height: fit-content;
          }
          @at-root html[dir="rtl"]
              section.homeLanding
              div.container
              h1.landing-header {
            font-size: 42px;
            height: 155px;
            @media (max-width: 767.98px) {
              font-size: 35px;
              height: 300px;
              padding: 0;
            }
          }
          // span {
          //   display: inline-block;
          //   margin-left: -8px;
          //   margin-right: 16px;
          //   position: relative;
          //   @media (max-width: 767.98px) {
          //     margin: 0px;
          //   }
          //   @at-root html[dir="rtl"]
          //       section.homeLanding
          //       div.container
          //       h1.landing-header
          //       span {
          //     margin-left: 16px !important;
          //     margin-right: -8px !important;
          //   }
          //   &::before {
          //     content: "";
          //     padding: 0 29px;
          //     height: 60px;
          //     position: absolute;
          //     left: -34px;
          //     bottom: 6px;
          //     z-index: -1;
          //     background-color: v.$landing_cta_color;
          //     border-radius: 29px;
          //     transform: skew(4deg) rotate(-5deg);
          //     animation: width-load 0.4s ease-out 0.2s both;
          //     @media (max-width: 767.98px) {
          //       display: none;
          //     }
          //   }
          // }
        }

        div.landing-title {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          gap: 10px;
          padding: 0 27px;
          line-height: 1;
          @at-root html[dir="rtl"] section.homeLanding div.landing-btns {
            margin-top: 60px !important;
          }
          @media (max-width: 767.98px) {
            flex-direction: column;
            margin-bottom: 40px;
            padding: 0;
          }
          a {
            color: v.$white_color;
            font-size: 17px;
            border-radius: 12px;
            text-decoration: none;
          }
          a.btn-start {
            background-color: v.$primary_color;
            padding: 15px 55px;
            font-weight: 700;
            @media (max-width: 767.98px) {
              min-width: 219px;
            }
          }
          span {
            line-height: 1.2;
            &:nth-child(1) {
              font-weight: 700;
              font-size: 25px;
              color: v.$primary_color;
            }
            &:nth-child(2) {
              font-weight: 600;
              font-size: 17px;
              color: #1d3c51;
            }
          }

          div.landing-btns {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
            gap: 20px;
            a.btn-docs {
              background-color: v.$secondary_color;
              font-weight: 800;
              color: #1d3c51;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 11px;
              padding: 12px 35px;
              @media (max-width: 767.98px) {
                min-width: 219px;
              }
              i {
                transform: scaleX(-1);
              }

              i {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                border: 2px solid #1d3c51;
                color: #1d3c51;
                padding: 3px;
                width: 25px;
                height: 25px;
                @at-root html[dir="rtl"]
                    section.homeLanding
                    div.landing-btns
                    button.btn-docs {
                  i {
                    transform: scaleX(-1);
                  }
                }
              }
            }
          }
        }
      }
    }
    div.hand-img {
      width: 568px;
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      margin-bottom: -11px;
      animation: show 4.5s 0s infinite alternate;
      &.one {
        opacity: 20%;
        width: 465px;
        animation: showup 2s 0s infinite alternate;
        @media (max-width: 767.98px) {
          width: 400px;
        }
      }
      &.two {
        opacity: 80%;
        width: 465px;
        transform: translateX(86px);
        animation: showup 2s 2s infinite alternate;
        @media (max-width: 767.98px) {
          width: 400px;
          transform: translateX(73px);
        }
        @at-root html[dir="rtl"]
            section.homeLanding
            div.container-wrapped
            div.hand-img.two {
          transform: translateX(-86px) scaleX(-1);
          @media (max-width: 767.98px) {
            transform: translateX(-74px) scaleX(-1);
          }
        }
      }
      @media (max-width: 767.98px) {
        width: 375px;
      }
      @media (min-width: 767.98px) and (max-width: 991.98px) {
        width: 359px;
      }
      @at-root html[dir="rtl"] section.homeLanding .hand-img {
        right: auto !important;
        left: 0;
        transform: scaleX(-1);
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
