@use "../../../../../common/variable" as v;

section.pricing-asks {
  div.container {
    margin-top: 30px;
    div.pricing-asks-top {
      text-align: center;
      h2 {
        a {
          text-decoration: none;
          font-size: 37px;
          word-spacing: -3px;
          color: v.$pricing_header;
          @media (max-width: 767.98px) {
            font-size: 30px;
          }
          @media (min-width: 767.98px) and (max-width: 991.98px) {
            font-size: 40px;
          }
        }
      }

      p {
        font-size: 22px;
        font-weight: 700;
        word-spacing: -2px;
        margin-top: 16px;
        color: v.$pricing_paragraph;
        @media (max-width: 767.98px) {
          font-size: 21px;
        }
        @media (min-width: 767.98px) and (max-width: 991.98px) {
          font-size: 26px;
        }
      }
    }

    div.pricing-asks-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      // gap: 41px;
      gap: 20px;
      margin-top: 45px;
    }
  }
}
