@use "./../../../common/variable" as v;

div.container {
  &.not-found {
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      font-size: 24px;
      color: v.$footer_color;
      font-weight: 600;
    }
  }
}
