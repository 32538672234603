@use "../../../../../../common/variable" as v;

div.item-contact {
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 15px;
  gap: 29px;
  background-color: v.$secondary_color_overlay;
  margin: 0 20%;
  &.item-contact2 {
    margin-bottom: 230px;
  }
  @media (max-width: 767.98px) {
    padding: 20px;
    gap: 20px;
    margin: 0;
    &.item-contact2 {
      margin-bottom: 0;
    }
  }
  div.item-image {
    width: 67px;
    height: 67px;
    i {
      width: 100%;
      height: 100%;
      font-size: 2.2rem;
      background-color: v.$primary_color;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
    }
  }

  div.item-content {
    flex-grow: 1;
    h4.item-header {
      color: #1c3b52;
      font-size: 23px;
      @media (max-width: 767.98px) {
        font-size: 21px;
      }
    }

    a.item-contact-address {
      color: #446074;
      color: #0b97fc;
      font-size: 20px;
      width: fit-content;
      text-decoration: none;
      @media (max-width: 767.98px) {
        font-size: 18px;
      }
    }
  }
}
