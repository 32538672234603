@use "./../../../common/variable" as v;

main.privacy {
  div.container {
    margin-top: v.$margin_page_top;
    padding: 0;
    div.privacy-top {
      text-align: center;
      padding: 0 15px;
      h2 {
        color: v.$pricing_header;
        font-size: 37px;
        font-weight: 800;
        margin-bottom: 7px;
      }

      p {
        color: v.$pricing_paragraph;
        font-size: 22px;
        font-weight: 700;
      }
    }

    div.privacy-bottom {
      background-color: v.$secondary_color_overlay;
      margin-top: 85px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      padding: 90px 36px;
      border-radius: 28px;
      direction: ltr;

      div.privacy-polices {
        hr {
          margin: 7px 0;
        }
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        div.cart-item {
          display: flex;
          flex-direction: column;
          h3 {
            color: v.$pricing_header;
            margin-bottom: 8px;
            font-size: 25px;
            font-weight: bold;
            letter-spacing: 1px;
          }

          ul {
            h4 {
              color: v.$pricing_header;
              margin: 0 0 15px 25px;
              font-size: 20px;
              font-weight: 700;
            }
            li {
              color: #181e22;
              margin-left: 3rem;
              font-weight: 600;
            }
            li.sub-li {
              margin-left: 5rem;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  div.container {
    width: fit-content;
    div.privacy-bottom {
      width: fit-content;
    }
  }
}
