@use "./../../../../../common/variable" as v;

section.features {
  margin-top: 45px;
  div.container {
    div.features-top {
      text-align: center;
      h2.features-header {
        a {
          text-decoration: none;
          color: v.$header_color;
          font-size: 37px;
          font-weight: 800;
          margin-bottom: 11px;
          @media (max-width: 767.98px) {
            font-size: 47px;
          }
        }
      }

      p.features-paragraph {
        color: v.$sec_header_paragraph;
        font-size: 22px;
        font-weight: 700;
        @media (max-width: 767.98px) {
          font-size: 24px;
        }
      }
    }
    div.features {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 30px;
      div.features-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        gap: 25px;
        @media (max-width: 767.98px) {
          flex-direction: column;
          gap: 30px;
          text-align: center;
        }
        @media (min-width: 767.98px) and (max-width: 991.98px) {
          // gap: 25px;
        }
        div:is(.features-left, .features-right) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 30px;
          @media (max-width: 767.98px) {
            gap: 30px;
          }
        }
        div.features-left {
          text-align: start;
          @media (max-width: 767.98px) {
            text-align: center;
            gap: 30px;
          }
        }

        div.hand-center-img {
          width: 420px;
          position: relative;
          z-index: -1;
          @media (max-width: 767.98px) {
            width: 375px;
            display: none;
          }
          @media (min-width: 767.98px) and (max-width: 991.98px) {
            width: 524px;
          }
          img {
            width: 100%;
            height: 100%;
          }
          img.feature-anim {
            width: 45px;
            height: 45px;
            position: absolute;
            &.feature-anim1 {
              top: 0;
              left: -37px;
            }
            &.feature-anim2 {
              width: 60px;
              height: 53px;
              top: -105px;
              right: 52px;
            }
            &.feature-anim3 {
              top: 24px;
              right: -37px;
            }
          }
        }

        div.features-right {
          text-align: start;
          @media (max-width: 767.98px) {
            text-align: center;
            gap: 60px;
          }
        }
      }
      div.features-bottom {
        display: flex;
        justify-content: space-between;
        gap: 25px;
        @media (max-width: 767.98px) {
          flex-direction: column;
          text-align: center;
          gap: 30px;
        }
        @media (min-width: 768px) and (max-width: 991.98px) {
          gap: 82px;
        }
      }
    }
  }
}
