@use "../../../../../common/variable" as v;

section.contact-items {
  margin-top: 50px;
  position: relative;
  z-index: 0;
  .container {
    position: relative;
    z-index: 0;
    margin-top: 70px;
    display: grid;
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    @media (max-width: 991.98px) {
      grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    }
    gap: 36px;
    margin: auto;
    padding: 0;
    @media (min-width: 575.98px) and (max-width: 767.98px) {
      justify-content: center;
      gap: 22px;
      width: 500px;
    }
  }
}
