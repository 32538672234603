@use "../../../../../common/variable" as v;

$color_1: rgba(83, 99, 135, 0.5);
$color_2: black;
$color_3: #373737;
$color_4: white;
$background-color_1: transparent;
$background-color_2: v.$primary_color;
$border-color_1: #6a64f1;

section.contact-form {
  div.container {
    @media (min-width: 575.98px) and (max-width: 767.98px) {
      width: calc(100% - 50px);
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin-top: 80px;
    background-color: v.$secondary_color_overlay;
    border-radius: 35px;
    overflow: hidden;
    @media (max-width: 991.98px) {
      flex-direction: column;
      gap: 10px;
    }

    div.contact-form-top {
      width: 940px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 991.98px) {
        padding-top: 20px;
        width: 100%;
      }
      div.hand-image {
        width: 388px;
        position: relative;
        z-index: 1;
        @media (max-width: 767.98px) {
          width: 325px;
        }
        img {
          width: 100%;
          height: 100%;
        }
        &::before {
          content: "";
          position: absolute;
          width: 382px;
          height: 382px;
          background-color: v.$accent_color_overlay;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: -1;
          @media (max-width: 767.98px) {
            width: 330px;
            height: 330px;
          }
        }
      }
    }

    div.contact-form-bottom {
      padding: 27px 20px 20px;
      div.form-header {
        h3 {
          font-size: 33px;
          word-spacing: 1px;
          font-weight: 700;
          color: v.$pricing_header;
          margin-bottom: 9px;
          text-align: center;
        }

        p {
          font-size: 19px;
          line-height: 1.9;
          word-spacing: 1px;
          margin-bottom: 27px;
        }
        @media (max-width: 991.98px) {
          text-align: center;
        }
      }
      .formbold-mb-3 {
        margin-bottom: 15px;
      }
      .formbold-main-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $background-color_1;
        @media (min-width: 575.98px) and (max-width: 767.98px) {
          padding: 0;
        }
      }
      .formbold-form-wrapper {
        margin: 0 auto;
        width: 100%;
      }
      .formbold-input-wrapp {
        > div {
          display: flex;
          gap: 20px;
        }
      }
      .formbold-input-flex {
        display: flex;
        gap: 20px;
        margin-bottom: 15px;
        > div {
          width: 100%;
        }
      }
      .formbold-form-input {
        width: 100%;
        padding: 13px 22px;
        border-radius: 10px;
        border: 1px solid #dde3ec;
        background: #ffffff;
        font-weight: 500;
        outline: none;
        resize: none;
        &::placeholder {
          color: $color_1;
        }
        &:focus {
          border-color: $border-color_1;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
          color: $color_2;
        }
        @media (max-width: 575.98px) {
          font-size: 17px;
        }
      }
      select.formbold-form-input {
        color: $color_1;
      }
      select.plan {
        color: black;
      }
      .formbold-form-input[type="date"] {
        &::-webkit-datetime-edit-text {
          color: $color_1;
        }
        &::-webkit-datetime-edit-month-field {
          color: $color_1;
        }
        &::-webkit-datetime-edit-day-field {
          color: $color_1;
        }
        &::-webkit-datetime-edit-year-field {
          color: $color_1;
        }
      }
      .formbold-form-label {
        color: $color_3;
        font-weight: bold;
        font-size: 19px;
        line-height: 24px;
        display: block;
        margin: 10px 0;
        @media (max-width: 575.98px) {
          font-size: 17px;
        }
      }
      div.btn-group {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        button.formbold-btn {
          text-align: center;
          border-radius: 10px;
          padding: 10px 20px;
          border: none;
          background-color: $background-color_2;
          color: $color_4;
          cursor: pointer;
          margin-top: 25px;
          font-weight: bold;
          font-size: 1rem;
          letter-spacing: 1px;
          &:hover {
            box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
          }
          i {
            margin-left: 5px;
          }
          @media (max-width: 575.98px) {
            padding: 10px 20px;
            font-size: 1rem;
          }
        }
        .disabled {
          background-color: #38b0ff75 !important;
        }
      }
      // //////////////////////////////////////

      // //////////////////////////////////////
      @media (max-width: 575.98px) {
        div.formbold-main-wrapper {
          padding: 0;
        }
        div.formbold-form-wrapper {
          padding: 0;
        }
      }
    }
  }
}
