@use "./../../../../../common/variable" as v;
section.how-omega-work {
  position: relative;
  z-index: 11;
  div.container {
    margin-top: 105px;
    @media (max-width: 767.98px) {
      margin-top: 20px;
    }
    @media (min-width: 767.98px) and (max-width: 991.98px) {
      margin-top: 92px;
    }
    @at-root html[dir="rtl"] section.how-omega-work div.container {
      margin-top: 109px;
      @media (max-width: 767.98px) {
        margin-top: 24px;
      }
      @media (min-width: 767.98px) and (max-width: 991.98px) {
        margin-top: 20px;
      }
    }
    div.how-omega-work-top {
      text-align: start;
      @media (max-width: 767.98px) {
        text-align: center;
      }
      h2.how-omega-work-header {
        a {
          text-decoration: none;
          color: v.$how_work_header;
          font-size: 37px;
          font-weight: 800;
          word-spacing: 5px;
          max-width: 300px;
          line-height: 1.2;
          @media (max-width: 767.98px) {
            font-size: 36px;
            max-width: none;
            margin-bottom: 2px;
          }
        }
      }

      p.how-omega-work-paragraph {
        color: v.$how_work_header;
        font-size: 20px;
        font-weight: 500;
        max-width: 350px;
        line-height: 1.7;
        @media (max-width: 767.98px) {
          font-size: 22px;
        }
      }
    }
    div.steps-wrapped {
      position: relative;
      div.steps-container {
        display: flex;
        justify-content: center;
        gap: 25px;
        text-align: center;
        @media (max-width: 767.98px) {
          flex-direction: column;
          align-items: center;
        }
      }
      div.hand-img-line {
        position: absolute;
        bottom: 125px;
        left: 0;
        width: 1044px;
        z-index: -1;
        @media (max-width: 767.98px) {
          display: none;
        }
        @media (min-width: 767.98px) and (max-width: 991.98px) {
          bottom: 199px;
          width: 769px;
          transform: rotate(-11deg);
        }
        @at-root html[dir="rtl"]
            section.how-omega-work
            div.container
            div.steps-wrapped
            div.hand-img-line {
          left: auto;
          right: 0;
          bottom: 89px;
          transform: scaleX(-1);
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    span.note {
      display: block;
      text-align: start;
      font-size: 12px;
      color: #77898f;
      margin-top: 10px;
      @media (max-width: 767.98px) {
        margin-top: 20px;
      }
    }
  }
  .circleBg {
    position: absolute;
    width: 405px;
    height: 405px;
    border-radius: 50%;
    background-color: v.$secondary_color_overlay;
    top: -28px;
    right: -179px;
    z-index: -1;
    @media (max-width: 767.98px) {
      display: none;
    }
    @at-root html[dir="rtl"] section.how-omega-work .circleBg {
      right: auto;
      left: -179px;
      top: 26px;
    }
  }
}
