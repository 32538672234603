@use "./../../../../../common/variable" as v;

section.pricing {
  margin-top: 45px;
  overflow-x: hidden;
  div.container {
    div.pricing-top {
      text-align: center;
      h2.pricing-header {
        a {
          text-decoration: none;
          font-size: 37px;
          font-weight: 800;
          margin-bottom: 4px;
          color: v.$pricing_header;
          word-spacing: -1px;
        }
      }

      p.pricing-paragraph {
        font-size: v.$subtitle;
        font-weight: 700;
        color: v.$pricing_paragraph;
        word-spacing: -1px;
      }
    }
    span.note {
      display: block;
      text-align: start;
      font-size: 12px;
      color: #77898f;
    }
  }
}

div.pricing-center {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: -30px;

  @media (max-width: 767.98px) {
    flex-direction: column;
  }

  div.pricing-item {
    // background-color: v.$accent_color_overlay;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 80%;
    @media (max-width: 767.98px) {
      flex-direction: column;
      width: 100%;
    }
    div.body-box {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (max-width: 767.98px) {
        flex-direction: column;
      }
      div.pricing-item-top {
        h4 {
          font-size: 35px;
        }
      }

      div.pricing-item-advantage {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        margin-left: 40px;
        gap: 40px;

        @media (max-width: 767.98px) {
          flex-direction: column;
          align-items: flex-start;
          margin-left: 0;
          gap: 0;
          width: 100%;
        }

        div.advantage-1 {
          display: flex;
          flex-direction: column;
        }
        div.advantage-2 {
          display: flex;
          flex-direction: column;
        }
      }
    }
    div.free-plan {
      display: flex;
      flex-direction: column;
      width: 20%;
      gap: 10px;
      @media (max-width: 767.98px) {
        width: 100%;
      }
      a {
        @media (min-width: 767.98px) and (max-width: 991.98px) {
          font-size: 16px;
        }
      }
      p {
        text-align: center;
        color: #8d9da8;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
