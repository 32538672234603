@use "../../../style/common/variable" as v;

footer.footer {
  overflow-x: hidden;
  padding: 30px 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  background-color: v.$footer_color;
  position: relative;
  z-index: 2;
  margin-top: 100px;
  div.footer-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    div.footer-div-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 40px;
      margin: 0 350px;
      @media (max-width: 767.98px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0 30px;
      }
      div.container-title {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 400px;
        height: 230px;
        @media (max-width: 767.98px) {
          width: 100%;
          height: fit-content;
        }
        div.footer-title {
          h1 {
            font-size: 1.5rem;
            color: #ebe8e8;
            margin: 0 0 10px 0;
            text-align: start;
            text-transform: uppercase;
            font-weight: 800;
          }
          p {
            color: #cac6c6ee;
            font-size: 0.9rem;
            text-align: justify;
          }
          @media (max-width: 767.98px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              text-align: center;
            }
          }
        }
      }
      div.container-links {
        width: 230px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 230px;
        @media (max-width: 767.98px) {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  div.links-footer {
    ul.list-ul {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 16px;
      @media (max-width: 767.98px) {
        gap: 5px;
        font-size: 15px;
        flex-direction: column;
      }
      li.list-item {
        margin-top: 5px;
        width: 100%;
        text-align: start;
        @media (max-width: 767.98px) {
          text-align: center;
        }
        a.list-item {
          text-decoration: none;
          color: #e3e3e3d9;
          transition: color 0.2s linear;
          font-weight: 600;
          &:hover {
            color: v.$primary_color;
          }
        }
      }
    }
  }

  hr {
    width: 100%;
    border-top: 1px solid v.$footer_line;
    border-bottom: 1px solid v.$footer_line;
  }
  div.container-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 0 4rem;

    @media (max-width: 767.98px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      padding: 5px;
    }
    div.icons {
      display: flex;
      align-items: center;
      gap: 20px;

      margin-left: 90px;
      i {
        color: #fff;
        transition: color 0.2s linear;
        cursor: pointer;
        font-size: 22px;
        &:hover {
          color: v.$primary_color;
        }
      }
      @media (max-width: 767.98px) {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        i {
          font-size: 25px;
        }
      }
    }
    div.copy-right-footer {
      text-align: start;
      color: #8398ad;
      font-weight: bold;
      font-size: 13px;
      @media (max-width: 767.98px) {
        width: 100%;
        font-size: 15px;
        text-align: center;
      }
    }
  }
}
