@use "./variable" as v;

// button.change-lang {
//   //display: none;
//   -webkit-border-radius: 5px;
//   -moz-border-radius: 5px;
//   -ms-border-radius: 5px;
//   -o-border-radius: 5px;
//   border-radius: 5px;
//   border: 1px solid v.$secund_words_color;
//   background-color: v.$main-section-color;
//   color: v.$secund_words_color;
//   padding: 3px;
//   z-index: 20;
// }

.form-error-msg {
  color: red;
  position: absolute !important;
  background-color: transparent !important;
  left: 0;
  bottom: -31px;
  font-size: 12px;
}
