// use common
@use "./common/normalize";
@use "./common/globalRules";
@use "./common/myFramework";
@use "./common/tmPreload";
// UI
// navLinks
@use "./UI/navLinks/navLinks";
// pages
@use "./UI/pages/home/home";
@use "./UI/pages/loginAndSignUp/loginAndSignUp";
@use "./UI/pages/notfound/notFound";
@use "./UI/pages/aboutUs/aboutUs";
@use "./UI/pages/contactUs/contactUs";
@use "./UI/pages/pricing/pricing";
@use "./UI/pages/Privacy/privacy-policy";
@use "./UI/pages/TermsOfService/terms-of-service";

// footer
@use "./UI/footer/footer";

.App {
  background-color: transparent;
  position: relative;
  z-index: 1;
}
.for-test {
  display: none;
  margin-top: 84px;
  width: calc(100vw - 10px);
  // filter: opacity(20%);
  // -webkit-filter: opacity(35%);

  position: absolute;
  left: 0;
  top: 0;
  img {
    display: block;
    width: 100%;
  }
  z-index: -1;
}
